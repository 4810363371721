import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import Hero from "components/hero/BackgroundAsImage.js";
const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
       <Hero />
      <ContactDetails
        cards={[
          {
            title: "天津",
            description: (
              <>
                <Address>
                  <AddressLine>滨海</AddressLine>
                  <AddressLine>高新区华苑产业区（环外）海泰华科大街7号1号楼729</AddressLine>
                </Address>
                <Email>contact@mingchentech.com</Email>
                <Phone>+86 18515667570</Phone>
                <Phone>+86 18698085968</Phone>
              </>
            )
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
